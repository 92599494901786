import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./layout/microfrontend-layout.html";
import customLoader from "./loader/custom-loader.html";
import customErrors from "./errors/errors-template.html";
import treeConfiguration from "./tree-config.json"

const data = {
  loaders: {
    customLoader
  },
  errors: {
    customErrors
  },
  props: {
    treeConfig: treeConfiguration
  }
}


const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
